import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

//Google analytics
import { gtag } from "ga-gtag"

const RouteChangeTracker = () => {
  let location = useLocation()

  useEffect(() => {
    gtag("event", "page_view", {
      page_location: location.pathname,
    })
  }, [location.pathname])

  return <div></div>
}

export default RouteChangeTracker
